import React, { Component } from "react";
//icon
import Logo from "../../../assets/images/icons/plus-marker.svg";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Label,
  InputGroup,
  InputGroupAddon,
  // Input,
  Col,
  Tooltip
} from "reactstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel as MobileCarousel } from "react-responsive-carousel";
import { connect } from "react-redux";
import actions from "../../../redux/groceryList/action";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faMinusCircle,
  faBars,
  // faAngleLeft,
  // faAngleRight,
  faShoppingCart
} from "@fortawesome/free-solid-svg-icons";

import HatChefIcon from "../../../assets/images/icons/hatchef.svg";
import SpecsIcon from "../../../assets/images/icons/specs.svg";
import PromoIcon from "../../../assets/images/icons/promo.svg";
import InfoIcon from "../../../assets/images/icons/info.svg";
import WorldIcon from "../../../assets/images/icons/world.svg";
import PlayIcon from "../../../assets/images/icons/play.svg";

import { NotificationManager } from "react-notifications";

import { Textfit } from "react-textfit";

import "../../../assets/css/custom.css";

import AddProd from "../../../assets/images/icons/white_add_prod.png";
import Facebook from "../../../assets/images/icons/facebook.svg";
import Whatsapp from "../../../assets/images/icons/whatsapp.svg";
import { serverResponse } from "../../../util/fakeServer";

import { strings } from "../../../util/data/strings";
import RelatedProduct from "../RelatedProduct";

// import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import { isIOS } from "react-device-detect";
import ReactGA from "react-ga";
import { gaEventLabel } from "../../../settings/gaConfig";
import Adsense from "../Adsense";
import Select from "react-select";
import { isModalOpen } from "../../../redux/carousel/action";

import ReactReadMoreReadLess from "react-read-more-read-less";

// import CarouselRelated from 'nuka-carousel';
import Container from "reactstrap/lib/Container";
import { defaultLanguage, gAInitializer } from "../../../helper/utility";
import ReadMore from "../../common/ReadMore";
import { language } from "../../../languageProvider/language/language";

const armando_landolfi = require("assets/images/armando_landolfi.png");
const landolfi = require("assets/images/landolfi.png");

const productUrl = serverResponse.config.shareFlyerURL;

class PlusMarkerDefault extends Component {
  setModal = (swipe) => {
    this.props.setSwipe(swipe);
    this.setState({
      modal: !this.state.modal,
      quantity: this.props.info.quantity_step
        ? this.props.info.quantity_step
        : 1,
      varietySelected: this.props.info.varieties
        ? this.props.info.varieties[0]
        : ""
    });
  };

  state = {
    modal: false,
    quantity: this.props.info.quantity_step ? this.props.info.quantity_step : 1,
    varietySelected: this.props.info.varieties
      ? {
          value: this.props.info.varieties[0],
          label: this.props.info.varieties[0]
        }
      : null,
    saveTooltipOpen: false,
    products: [],
    activeIndex: 0,
    animating: false,
    prices:
      this.props.info.prices !== null &&
      this.props.info.prices !== undefined &&
      this.props.info.prices.length > 1
  };

  componentDidMount() {
    if (serverResponse.config.ga_active) {
      gAInitializer();
    }
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };
    document.addEventListener("touchmove", disablePinchZoom, {
      passive: false
    });
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom);
    };
  }

  componentWillMount = () => {
    let tmpProducts = [];
    serverResponse.leaflet.pages.forEach((page) => {
      tmpProducts = tmpProducts.concat(page.interactivities);
    });
    this.setState({
      products: tmpProducts
    });
  };

  incrementQty = () => {
    this.setState({
      quantity:
        this.state.quantity +
        (this.props.info.quantity_step ? this.props.info.quantity_step : 1)
    });
  };

  decrementQty = () => {
    if (this.props.info.quantity_step) {
      if (this.state.quantity > this.props.info.quantity_step) {
        this.setState({
          quantity: this.state.quantity - this.props.info.quantity_step
        });
      }
    } else if (this.state.quantity > 1) {
      this.setState({ quantity: this.state.quantity - 1 });
    }
  };

  handleChangeVariety = (e) => {
    this.setState({
      varietySelected: e.value
    });
  };

  handleAddProduct = () => {
    let obj = Object.assign({}, this.props.info);
    obj.quantity = this.state.quantity;
    obj.done = false;
    obj.variety = this.state.varietySelected;
    this.props.addProduct(obj);
    NotificationManager.success(
      language[defaultLanguage].controlla_la_tua_lista_della_spesa,
      language[defaultLanguage].prodotto_aggiunto,
      800
    );
    if (document.getElementById("selected-" + this.props.id) != null)
      document.getElementById("selected-" + this.props.info.item_id).style[
        "display"
      ] = "inline";
  };

  shareOnFacebook = () => {
    if (serverResponse.config.ga_active) {
      ReactGA.event({
        category: `${serverResponse.config.release_id}-${serverResponse.leaflet.id}`,
        action: gaEventLabel.share_product_facebook,
        label: String(this.props.info.item_id)
      });
    }

    let fb_share_url = "https://www.facebook.com/share.php?u=";
    let page_url =
      productUrl + "/share/product.php?c=" + this.props.info.item_id;
    window.open(
      fb_share_url + encodeURIComponent(page_url),
      "_blank",
      "location=yes,height=570,width=520,scrollbars=yes,status=yes"
    );
  };

  shareOnWhatsapp = () => {
    if (serverResponse.config.ga_active) {
      ReactGA.event({
        category: `${serverResponse.config.release_id}-${serverResponse.leaflet.id}`,
        action: gaEventLabel.share_product_whatsapp,
        label: String(this.props.info.item_id)
      });
    }

    let page_url =
      productUrl + "/share/product.php?c=" + this.props.info.item_id;
    if (window.ReactNativeWebView !== undefined && isIOS) {
      let link = encodeURIComponent(page_url);
      let title = serverResponse.config.shareProductSentence;
      var str = title + " " + link;
      var obj = { action: "whatsapp", value: str };
      window.ReactNativeWebView.postMessage(JSON.stringify(obj));
    } else {
      window.open(
        "http://api.whatsapp.com/send?text=" +
          serverResponse.config.shareProductSentence +
          " " +
          encodeURIComponent(page_url),
        "_blank",
        "location=yes,height=570,width=520,scrollbars=yes,status=yes"
      );
    }
  };

  toggleSaveTooltip = () => {
    this.setState({ saveTooltipOpen: !this.state.saveTooltipOpen });
  };

  handleRelatedClick = (item_id) => {
    this.setModal(true);
    let marker = document.getElementById("plus-" + item_id);

    if (!marker) {
      return;
    }
    marker.click();
  };

  render() {
    const markers = this.props.info.markers.map((element, key) => {
      switch (element.type) {
        case "plus": {
          return null;
        }
        case "info": {
          return (
            <img
              key={key}
              src={InfoIcon}
              className="icon-marker"
              alt="Info"
              onClick={() => {
                this.props.navMarkers("info");
                this.setModal(false);
                if (serverResponse.config.ga_active) {
                  ReactGA.event({
                    category: `${serverResponse.config.release_id}-${serverResponse.leaflet.id}`,
                    action: gaEventLabel.open_product_curiosity,
                    label: String(this.props.info.item_id)
                  });
                }
              }}
            />
          );
        }
        case "world": {
          return (
            <img
              key={key}
              src={WorldIcon}
              className="icon-marker"
              alt="World"
              onClick={() => {
                this.props.navMarkers("world");
                this.setModal(false);
                ReactGA.event({
                  category: `${serverResponse.config.release_id}-${serverResponse.leaflet.id}`,
                  action: gaEventLabel.open_product_link,
                  label: String(this.props.info.item_id)
                });
              }}
            />
          );
        }
        case "hat-chef": {
          return (
            <img
              key={key}
              src={HatChefIcon}
              className="icon-marker"
              alt="World"
              onClick={() => {
                this.props.navMarkers("hatchef");
                this.setModal(false);
                if (serverResponse.config.ga_active) {
                  ReactGA.event({
                    category: `${serverResponse.config.release_id}-${serverResponse.leaflet.id}`,
                    action: gaEventLabel.open_product_recipe,
                    label: String(this.props.info.item_id)
                  });
                }
              }}
            />
          );
        }
        case "play": {
          return (
            <img
              key={key}
              src={PlayIcon}
              className="icon-marker"
              alt="World"
              onClick={() => {
                this.props.navMarkers("play");
                this.setModal(false);
                if (serverResponse.config.ga_active) {
                  ReactGA.event({
                    category: `${serverResponse.config.release_id}-${serverResponse.leaflet.id}`,
                    action: gaEventLabel.open_product_video,
                    label: String(this.props.info.item_id)
                  });
                }
              }}
            />
          );
        }
        case "specs": {
          return (
            <img
              key={key}
              src={SpecsIcon}
              className="icon-marker"
              alt="World"
              onClick={() => {
                this.props.navMarkers("specs");
                this.setModal(false);
                if (serverResponse.config.ga_active) {
                  ReactGA.event({
                    category: `${serverResponse.config.release_id}-${serverResponse.leaflet.id}`,
                    action: gaEventLabel.open_product_specifications,
                    label: String(this.props.info.item_id)
                  });
                }
              }}
            />
          );
        }
        case "promo": {
          return (
            <img
              key={key}
              src={PromoIcon}
              className="icon-marker-promo"
              alt="World"
              onClick={() => {
                this.props.navMarkers("promo");
                this.setModal(false);
                if (serverResponse.config.ga_active) {
                  ReactGA.event({
                    category: `${serverResponse.config.release_id}-${serverResponse.leaflet.id}`,
                    action: gaEventLabel.open_promo
                  });
                }
              }}
            />
          );
        }
        default:
          return null;
      }
    });

    const listImages_m = this.props.info.images.map((element, key) => (
      <img
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = `${serverResponse.config.clientIcon}`;
        }}
        key={key}
        src={
          process.env.PUBLIC_URL +
          element.image_file +
          "?v=" +
          serverResponse.config.version
        }
        style={{ maxWidth: "350px" }}
        alt="img"
      />
    ));

    let varieties = "";
    let mobileVarieties = "";
    let options = [];
    let visibleClass = "";
    if (this.props.info.varieties.length === 1) {
      visibleClass = "d-none";
    }
    if (this.props.info.varieties) {
      if (this.props.info.varieties.length > 0) {
        this.props.info.varieties.map((element, key) => {
          options.push({ value: element, label: element });
          return 0;
        });
        varieties = (
          <Row style={{ marginTop: "30px" }}>
            <Col md="12" className={visibleClass}>
              <Label for="variety">
                {serverResponse.config.hide_grocery_list
                  ? "Varianti"
                  : "Scegli"}
                :
              </Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <span className="input-group-text icon-input-group">
                    <FontAwesomeIcon icon={faBars} />
                  </span>
                </InputGroupAddon>
                <Select
                  isSearchable={false}
                  className="select-variety"
                  defaultValue={options[0]}
                  onChange={this.handleChangeVariety}
                  options={options}
                />
              </InputGroup>
            </Col>
          </Row>
        );

        mobileVarieties = (
          <Col
            xs="12"
            className={visibleClass}
            style={{ marginBottom: "10px" }}
          >
            <Label for="variety">
              {serverResponse.config.hide_grocery_list ? "Varianti" : "Scegli"}:
            </Label>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <span className="input-group-text icon-input-group">
                  <FontAwesomeIcon icon={faBars} />
                </span>
              </InputGroupAddon>
              <Select
                isSearchable={false}
                className="select-variety"
                defaultValue={options[0]}
                onChange={this.handleChangeVariety}
                options={options}
              />
            </InputGroup>
          </Col>
        );
      }
    }

    let brandImg = "";
    if (
      serverResponse.config.brandImage !== "" &&
      this.props.info.pam === true
    ) {
      brandImg = (
        <img
          style={{ width: "15%" }}
          src={process.env.PUBLIC_URL + serverResponse.config.brandImage}
          alt="img"
        />
      );
    }

    let cardImg = "";
    if (
      serverResponse.config.underPriceImage !== "" &&
      serverResponse.config.underPriceImage !== null &&
      this.props.info.underpriced_product === true
    ) {
      cardImg = (
        <img
          style={{ width: "50%", marginTop: "10px" }}
          src={process.env.PUBLIC_URL + serverResponse.config.underPriceImage}
          alt="img"
        />
      );
    }

    let related = [];
    if (this.props.info.related) {
      let temp_key = 1;
      this.props.info.related.map((element) => {
        this.state.products.map((prod, key) => {
          if (prod.item_id === element) {
            related.push(
              <RelatedProduct
                key={temp_key}
                prod={prod}
                handleClick={this.handleRelatedClick}
              />
            );
          }
          temp_key++;
          return 0;
        });
        return 0;
      });
    }

    // let responsive = {
    //     0: { items: 5 },
    //     1024: { items: 5 },
    // }

    var closeBtn = (
      <Button
        color="secondary"
        onClick={() => {
          if (this.props.zoom !== undefined) {
            this.props.zoom.reset();
          }
          this.setModal(true);
        }}
      >
        <i className="fas fa-arrow-left"></i>
        <strong> {language[defaultLanguage].indietro}</strong>
      </Button>
    );

    const stock = {
      y: {
        text: "Disponibile",
        color: "green",
        icon: <i className="fas fa-check"></i>
      },
      n: {
        text:
          "Non disponibile" +
          (this.props.info.tdc !== null
            ? " - arrivo tra " + this.props.info.tdc + " giorni"
            : ""),
        color: "red",
        icon: <i className="fas fa-times"></i>
      },
      c: {
        text: this.props.info.available_from,
        color: "var(--secondary-color)",
        icon: <i className="far fa-clock"></i>
      }
    };

    var disp = stock[this.props.info.stock];
    var prices = null;
    if (
      this.props.info.prices !== null &&
      this.props.info.prices !== undefined &&
      this.props.info.prices.length > 1
    ) {
      prices = this.props.info.prices.map((p) => {
        return (
          <p style={{ marginRight: "5px" }} key={p.from}>
            <span style={{ fontWeight: "bold" }}>
              &euro;{" "}
              {parseFloat(p.price)
                .toFixed(2)
                .replace(".", ",")}
            </span>{" "}
            per quantità: <span style={{ fontStyle: "italic" }}>{p.from}</span>
          </p>
        );
      });
    }

    return (
      <div
        className={"marker " + serverResponse.config.iconAnimation}
        type="plus"
        style={{
          display: this.props.info.hidden_price ? "none" : ""
        }}
      >
        <img
          id={"plus-" + this.props.info.item_id}
          src={Logo}
          className={
            serverResponse.config.largeIcon
              ? "icon-marker-large"
              : "icon-marker"
          }
          alt="Plus"
          onClick={() => {
            this.setModal(false);
            if (serverResponse.config.ga_active) {
              ReactGA.event({
                category: `${serverResponse.config.release_id}-${serverResponse.leaflet.id}`,
                action: gaEventLabel.open_product,
                label: String(this.props.info.item_id)
              });
            }
          }}
        />

        <Modal
          id="ModalDetail"
          // style={{
          //   height: "90vh",
          // }}
          isOpen={this.state.modal}
          toggle={() => this.setModal(true)}
          size="xl"
          onOpened={() => {
            this.props.isModalOpen(true);
            if (this.props.disableSwipe !== undefined)
              this.props.disableSwipe();
          }}
          onClosed={() => {
            this.props.isModalOpen(false);
            if (this.props.enableSwipe !== undefined) this.props.enableSwipe();
          }}
        >
          <ModalHeader>
            <div className="container">
              <div className="row">
                <div className="col-3 m-auto text-center">
                  <img
                    className="img-fluid"
                    style={{
                      maxWidth: "45%"
                    }}
                    src={
                      this.props.info.fidelity_product
                        ? armando_landolfi
                        : landolfi
                    }
                    alt="logo"
                  />
                </div>
              </div>
            </div>
          </ModalHeader>

          {/*Desktop version*/}
          <ModalBody className="modal-plus-desktop">
            <Row style={{ marginBottom: "16px" }}>
              <Col style={{ display: "flex", height: "30px" }}>
                <div className="markers-container" style={{ width: "100%" }}>
                  <div className="vertical">{markers}</div>
                  {this.props.info.stock !== null &&
                    this.props.info.stock !== undefined &&
                    serverResponse.config.manager_stock && (
                      <span style={{ color: disp.color, marginLeft: "5px" }}>
                        {disp.icon} {disp.text}
                      </span>
                    )}
                  {!serverResponse.config.hide_share_product && (
                    <img
                      src={Facebook}
                      className="share-icon"
                      style={{ marginLeft: "auto" }}
                      alt="facebook"
                      onClick={() => this.shareOnFacebook()}
                    />
                  )}
                  {!serverResponse.config.hide_share_product && (
                    <img
                      src={Whatsapp}
                      className="share-icon"
                      alt="whatsapp"
                      onClick={() => this.shareOnWhatsapp()}
                    />
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col
                id="modalCarousel"
                md="6"
                className="text-center"
                style={{ borderRight: "2px solid #513220" }}
              >
                {this.props.info.images.filter((el) => el.cropped === false)
                  .length === 1 && (
                  <img
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = `${serverResponse.config.clientIcon}`;
                    }}
                    src={
                      process.env.PUBLIC_URL +
                      this.props.info.images[0].image_file
                    }
                    alt="img"
                  />
                )}
                {this.props.info.images.filter((el) => el.cropped === false)
                  .length > 1 && (
                  <Carousel
                    activeIndex={this.state.activeIndex}
                    next={() => {
                      let listImages = this.props.info.images;
                      if (this.state.animating) return;
                      const nextIndex =
                        this.state.activeIndex === listImages.length - 1
                          ? 0
                          : this.state.activeIndex + 1;
                      this.setState({ activeIndex: nextIndex });
                    }}
                    previous={() => {
                      let listImages = this.props.info.images;
                      if (this.state.animating) return;
                      const nextIndex =
                        this.state.activeIndex === 0
                          ? listImages.length - 1
                          : this.state.activeIndex - 1;
                      this.setState({ activeIndex: nextIndex });
                    }}
                    interval={false}
                  >
                    {this.props.info.images.map((element, key) => {
                      return (
                        <CarouselItem
                          onExiting={() => this.setState({ animating: true })}
                          onExited={() => this.setState({ animating: false })}
                          key={key}
                        >
                          <img
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = `${serverResponse.config.clientIcon}`;
                            }}
                            style={{ maxWidth: "350px" }}
                            src={
                              process.env.PUBLIC_URL +
                              element.image_file +
                              "?v=" +
                              serverResponse.config.version
                            }
                            alt={"img"}
                          />
                        </CarouselItem>
                      );
                    })}
                    <CarouselControl
                      direction="prev"
                      directionText="Previous"
                      onClickHandler={() => {
                        let listImages = this.props.info.images;
                        if (this.state.animating) return;
                        const nextIndex =
                          this.state.activeIndex === 0
                            ? listImages.length - 1
                            : this.state.activeIndex - 1;
                        this.setState({ activeIndex: nextIndex });
                      }}
                    />
                    <CarouselControl
                      direction="next"
                      directionText="Next"
                      onClickHandler={() => {
                        let listImages = this.props.info.images;
                        if (this.state.animating) return;
                        const nextIndex =
                          this.state.activeIndex === listImages.length - 1
                            ? 0
                            : this.state.activeIndex + 1;
                        this.setState({ activeIndex: nextIndex });
                      }}
                    />
                  </Carousel>
                )}
              </Col>
              <Col md="6">
                <Textfit mode="multi" min={30} max={30}>
                  <span
                    className="field1_landolfi"
                    dangerouslySetInnerHTML={{
                      __html: this.props.info.field1
                    }}
                    style={{
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      fontSize: "25px"
                    }}
                  ></span>
                </Textfit>
                <Textfit mode="multi" min={15} max={15}>
                  <span
                    style={{
                      textTransform:
                        this.props.info.field4 === "false"
                          ? "inherit"
                          : "uppercase",
                      fontSize: "15px"
                    }}
                  >
                    {this.props.info.field2}
                    <span style={{ fontWeight: "900" }}>
                      {this.props.info.varieties.length === 1 &&
                        ` - ${this.props.info.varieties[0]}`}
                    </span>
                  </span>
                </Textfit>
                {/* {(!this.props.info.brand_logo ||
                  this.props.info.brand_logo === undefined ||
                  this.props.info.brand_logo === null) && (
                  <>
                    <h6>{this.props.info.field3}</h6>
                    <h6
                      className={
                        serverResponse.config.line_through === true
                          ? "barrato"
                          : null
                      }
                    >
                      {this.props.info.field4}
                    </h6>
                  </>
                )} */}

                {cardImg}

                {this.props.info.descrizione_estesa !== undefined &&
                  this.props.info.descrizione_estesa !== "" && (
                    <Row>
                      <Col className="wrapText">
                        {/* <p style={{ whiteSpace: "pre-wrap" }}>
                          {this.props.info.descrizione_estesa}
                        </p> */}
                        <ReadMore>
                          {this.props.info.descrizione_estesa}
                        </ReadMore>
                        {/* <ReactReadMoreReadLess
                          charLimit={200}
                          ellipsis={"..."}
                          readMoreText={"Leggi tutto ▼"}
                          readLessText={"Chiudi ▲"}
                          readMoreStyle={{
                            color: "var(--secondary-color)",
                            fontWeight: "bold",
                            cursor: "",
                            whiteSpace: "pre-wrap",
                          }}
                          readLessStyle={{
                            color: "var(--secondary-color)",
                            fontWeight: "bold",
                            cursor: "pointer",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          {this.props.info.descrizione_estesa}
                        </ReactReadMoreReadLess> */}
                      </Col>
                    </Row>
                  )}
                {varieties}

                {!serverResponse.config.hide_grocery_list && (
                  <Row style={{ marginTop: "30px" }}>
                    <Col md="12">
                      <div
                        className="container-add-to-list"
                        style={{ justifyContent: "space-between" }}
                      >
                        {!this.props.info.one_and_one_gratis && (
                          <div className="container-add-to-list">
                            <FontAwesomeIcon
                              className="change-product-quantity-icon"
                              icon={faMinusCircle}
                              onClick={() => this.decrementQty()}
                            />
                            <h6>{this.state.quantity}</h6>
                            <h6 style={{ fontWeight: "bold" }}>
                              {this.props.info.grocery_label}
                            </h6>
                            <FontAwesomeIcon
                              className="change-product-quantity-icon"
                              icon={faPlusCircle}
                              onClick={() => this.incrementQty()}
                            />
                          </div>
                        )}
                        {!this.props.info.one_and_one_gratis && (
                          <div className="container-add-to-list">
                            <Button
                              id={"btnSave_" + this.props.info.item_id}
                              disabled={
                                this.props.info.stock !== "y" &&
                                this.props.info.stock !== null &&
                                serverResponse.config.manager_stock
                              }
                              color="primary"
                              onClick={() => {
                                this.handleAddProduct();
                                ReactGA.event({
                                  category: `${serverResponse.config.release_id}-${serverResponse.leaflet.id}`,
                                  action: gaEventLabel.add_to_grocery_list,
                                  label: String(this.props.info.item_id)
                                });
                              }}
                            >
                              {/*<FontAwesomeIcon icon={faShoppingBasket} />*/}
                              {!serverResponse.config
                                .send_grocery_list_to_market && (
                                <img
                                  src={AddProd}
                                  style={{
                                    color: "#00ff00",
                                    width: "30px",
                                    cursor: "pointer"
                                  }}
                                  alt="img"
                                />
                              )}
                              {serverResponse.config
                                .send_grocery_list_to_market && (
                                <FontAwesomeIcon
                                  icon={faShoppingCart}
                                  style={{ fontSize: "35px" }}
                                />
                              )}
                            </Button>
                            {this.props.info.stock === "y" && (
                              <Tooltip
                                placement="top"
                                isOpen={this.state.saveTooltipOpen}
                                target={"btnSave_" + this.props.info.item_id}
                                toggle={this.toggleSaveTooltip}
                              >
                                {strings.saveBtnTooltip}
                              </Tooltip>
                            )}
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <span>
                  {related.length > 0 ? strings.relatedProductsTitle : ""}
                </span>
              </Col>
            </Row>
            <Row>{related}</Row>
          </ModalBody>

          {/*Mobile version*/}
          <ModalBody
            className="modal-plus-mobile"
            style={{ overflow: "scroll" }}
          >
            <Row style={{ marginBottom: "16px" }}>
              <Col
                style={
                  {
                    /*display: "flex"*/
                  }
                }
              >
                <div className="markers-container" style={{ width: "100%" }}>
                  <div className="vertical">{markers}</div>
                  {this.props.info.stock !== null &&
                    this.props.info.stock !== undefined &&
                    serverResponse.config.manager_stock && (
                      <span style={{ color: disp.color, marginLeft: "5px" }}>
                        {disp.icon} {disp.text}
                      </span>
                    )}
                  {!serverResponse.config.hide_share_product && (
                    <img
                      src={Facebook}
                      className="share-icon"
                      style={{ marginLeft: "auto" }}
                      alt="facebook"
                      onClick={() => this.shareOnFacebook()}
                    />
                  )}
                  {!serverResponse.config.hide_share_product && (
                    <img
                      src={Whatsapp}
                      className="share-icon"
                      alt="whatsapp"
                      onClick={() => this.shareOnWhatsapp()}
                    />
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col
                id="modalCarousel"
                style={{
                  marginBottom: "10px",
                  width: "100%",
                  borderRight: "1px solid brown"
                }}
              >
                <MobileCarousel
                  swipeable={true}
                  emulateTouch={true}
                  infiniteLoop={true}
                  showStatus={false}
                >
                  {listImages_m}
                </MobileCarousel>
              </Col>
              <Col style={{ marginBottom: "10px" }}>
                <Textfit mode="multi" min={30} max={30}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: this.props.info.field1
                    }}
                    style={{ textTransform: "uppercase", fontWeight: "bold" }}
                  ></span>
                </Textfit>
                <Textfit mode="multi" min={15} max={15}>
                  <span
                  // style={{
                  //   textTransform:
                  //     this.props.info.field4 === "false"
                  //       ? "capitalize"
                  //       : "uppercase"
                  // }}
                  >
                    {this.props.info.field2}
                    <span style={{ fontWeight: "900" }}>
                      {this.props.info.varieties.length === 1 &&
                        ` - ${this.props.info.varieties[0]}`}
                    </span>
                  </span>
                </Textfit>
                {(!this.props.info.brand_logo ||
                  this.props.info.brand_logo === undefined ||
                  this.props.info.brand_logo === null) && (
                  <>
                    <h6>{this.props.info.field3}</h6>
                  </>
                )}

                {/* <Textfit
                  className="text-right mt-5"
                  mode="multi"
                  min={15}
                  max={15}
                >
                  <img
                    className="float-left"
                    onError={(e) => (e.target.style.display = "none")}
                    src={this.props.info.brand_logo}
                    alt={this.props.info.brand}
                  ></img>
                  <span>{this.props.info.line}</span>
                </Textfit> */}
                {(!this.props.info.brand_logo ||
                  this.props.info.brand_logo === undefined ||
                  this.props.info.brand_logo === null) && (
                  <h6
                    className={
                      serverResponse.config.line_through === true
                        ? "barrato"
                        : null
                    }
                  >
                    {this.props.info.field4}
                  </h6>
                )}

                {brandImg}
                {cardImg}
              </Col>

              {this.props.info.descrizione_estesa !== undefined &&
                this.props.info.descrizione_estesa !== "" && (
                  <Container>
                    <Row>
                      <Col>
                        <ReadMore>
                          {this.props.info.descrizione_estesa}
                        </ReadMore>
                        {/* <ReactReadMoreReadLess
                          charLimit={200}
                          ellipsis={"..."}
                          readMoreText={"Leggi tutto ▼"}
                          readLessText={"Chiudi ▲"}
                          readMoreStyle={{
                            color: "var(--secondary-color)",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          readLessStyle={{
                            color: "var(--secondary-color)",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                        >
                          {this.props.info.descrizione_estesa}
                        </ReactReadMoreReadLess> */}
                      </Col>
                    </Row>
                  </Container>
                )}
              {this.props.info.strike_price &&
                parseFloat(this.props.info.strike_price) !==
                  parseFloat(this.props.info.price) && (
                  <Col md="12" className="mt-2">
                    <h4 className="barrato">
                      &euro; {this.props.info.strike_price}
                    </h4>
                  </Col>
                )}
              <Col
                xs="12"
                className="product-price"
                style={{ marginBottom: "10px" }}
              >
                <h3 style={{ fontWeight: "bold" }}>
                  <span style={{ fontSize: "3rem" }}>
                    {this.props.info.price_label}
                  </span>{" "}
                  {this.props.info.discount_rate !== undefined &&
                    parseFloat(this.props.info.discount_rate) !== 0 &&
                    this.props.info.discount_rate !== null && (
                      <span className="discount-rate">
                        {this.props.info.discount_rate}%
                      </span>
                    )}
                </h3>
              </Col>
              {<Col className="my-1">{prices}</Col>}
              {mobileVarieties}
              {!serverResponse.config.hide_grocery_list && (
                <Col xs="12">
                  <div
                    className="container-add-to-list"
                    style={{ justifyContent: "space-between" }}
                  >
                    {!this.props.info.one_and_one_gratis && (
                      <div className="container-add-to-list">
                        <FontAwesomeIcon
                          style={{ color: "var(--primary-color)" }}
                          icon={faMinusCircle}
                          onClick={() => this.decrementQty()}
                        />
                        <h6>{this.state.quantity}</h6>
                        <h6 style={{ fontWeight: "bold" }}>
                          {this.props.info.grocery_label}
                        </h6>
                        <FontAwesomeIcon
                          style={{ color: "var(--primary-color)" }}
                          icon={faPlusCircle}
                          onClick={() => this.incrementQty()}
                        />
                      </div>
                    )}
                    {!this.props.info.one_and_one_gratis && (
                      <div className="container-add-to-list">
                        <div className="text-center">
                          <Button
                            color="primary"
                            onClick={() => {
                              this.handleAddProduct();
                              ReactGA.event({
                                category: `${serverResponse.config.release_id}-${serverResponse.leaflet.id}`,
                                action: gaEventLabel.add_to_grocery_list,
                                label: String(this.props.info.item_id)
                              });
                            }}
                          >
                            {/*<FontAwesomeIcon icon={faShoppingBasket} />*/}
                            {!serverResponse.config
                              .send_grocery_list_to_market && (
                              <img
                                src={AddProd}
                                style={{
                                  color: "#00ff00",
                                  width: "30px",
                                  cursor: "pointer"
                                }}
                                alt="img"
                              />
                            )}
                            {serverResponse.config
                              .send_grocery_list_to_market && (
                              <FontAwesomeIcon
                                icon={faShoppingCart}
                                style={{ fontSize: "35px" }}
                              />
                            )}
                          </Button>
                          <div className="aggiungiButtonText">
                            {language[defaultLanguage].aggiungi}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </Col>
              )}
            </Row>
            <Row>
              <Col>
                <span>
                  {related.length > 0 ? strings.relatedProductsTitle : ""}
                </span>
              </Col>
            </Row>
            <Row>{related}</Row>
          </ModalBody>

          <ModalFooter>
            <i
              onClick={() => {
                if (this.props.zoom !== undefined) {
                  this.props.zoom.reset();
                }
                this.setModal(true);
              }}
              style={{ fontSize: "50px", color: "#513220" }}
              className="fas fa-times-circle"
            ></i>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addProduct: (newProduct) => {
      dispatch(actions.addProduct(newProduct));
    },
    isModalOpen: (bool) => {
      dispatch(isModalOpen(bool));
    }
  };
}

export default connect(null, mapDispatchToProps)(PlusMarkerDefault);
