import axios from "axios";
import { isMobileSafari, isSafari, osName } from "react-device-detect";
import { serverResponse } from "../util/fakeServer";
import { BREAKPOINT } from "./constant";
import ReactGA from "react-ga";
import { language } from "../languageProvider/language/language";

var isMobile = window.innerWidth < BREAKPOINT.lg;

const showOnePage = serverResponse.config.show_one_page || isMobile;
const hasIndex = serverResponse.leaflet.index !== null;
const nMax = serverResponse.leaflet.pages.length;

export const defaultLanguage = "it_IT";

const getInitialShowOnePage = () => {
  let r = false;
  if (window.innerWidth < BREAKPOINT.lg) {
    r = true;
  } else if (serverResponse.config.show_one_page) {
    r = true;
  }
  return r;
};

export const getValue = (input) => {
  let tempShowOnePage = global.showOnePage || getInitialShowOnePage();
  let tempHasIndex = serverResponse.leaflet.index !== null;
  if (Number.isNaN(input) || input <= 0) {
    return 0;
  } else if (input > nMax) {
    return tempShowOnePage ? nMax : Math.floor(nMax / 2);
    // return tempHasIndex ? temp_m : temp_m - 1;
  } else if (tempShowOnePage) {
    return tempHasIndex ? input : input - 1;
  } else {
    return input % 2 === 0 ? input / 2 : (input - 1) / 2;
  }
};

export const formatterText = (string) => {
  if (!string) {
    return;
  }
  return string
    .replace("&amp;", "&")
    .replace("&gt;", ">")
    .replace("&lt;", "<")
    .replace("&quot;", '"')
    .replace("&euro;", "€")
    .replace("&apos;", "'");
};

export const setLabelSlider = (index) => {
  let temp = index * 2 + 1 > nMax ? "" : " - " + (index * 2 + 1);
  let tempShowOnePage =
    global.showOnePage || serverResponse.config.show_one_page;
  let tempHasIndex = serverResponse.leaflet.index !== null;
  if (isNaN(index) || index <= 0) {
    return tempHasIndex && tempShowOnePage
      ? language[defaultLanguage].indice
      : 1;
  }
  if (tempShowOnePage) {
    if (index === 0) {
      return tempHasIndex ? language[defaultLanguage].indice : 1;
    } else {
      return tempHasIndex ? index : index + 1;
    }
  } else {
    if (index === 0) {
      return 1;
    } else {
      return index * 2 + temp;
    }
  }
};

export const defaultState = () => {
  let status,
    carouselIndex = 0,
    slideMax;

  if (!showOnePage && hasIndex) {
    status = 1;
    if (nMax % 2 !== 0) {
      slideMax = (nMax + 1) / 2;
    } else {
      slideMax = Math.round(nMax / 2) + 1;
    }
  } else if (!showOnePage && !hasIndex) {
    status = 2;
    if (nMax % 2 !== 0) {
      slideMax = Math.round(nMax / 2);
    } else {
      slideMax = nMax / 2;
    }
  } else if (showOnePage && hasIndex) {
    status = 3;
    slideMax = nMax + 1;
  } else {
    status = 4;
    slideMax = nMax;
  }

  if (isMobile && status === 3) {
    carouselIndex = 0;
  }

  if (nMax === 1) {
    slideMax = 1;
  }

  return {
    loading: false,
    slideMax,
    status,
    carouselIndex,
    labelIndex: setLabelSlider(carouselIndex),
    fromInput: false,
    firstLoad: false,
    zoomValue: 1,
    buttonCarouselDisabled: false,
    refZoom: {},
    modalOpen: false,
    sidebarRightOpen: false
  };
};

export const gAInitializer = () => {
  ReactGA.initialize(serverResponse.config.ga_tracking_id, {
    gaOptions: { cookieFlags: "max-age=7200;secure;samesite=none" }
  });
};

export const checkMobileOs = () => {
  let listOs = ["android", "ios"];

  return listOs.includes(osName.toLowerCase().trim());
};

export const lightenDarkenColor = (color, percent) => {
  var R = parseInt(color.substring(1, 3), 16);
  var G = parseInt(color.substring(3, 5), 16);
  var B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100);
  G = parseInt((G * (100 + percent)) / 100);
  B = parseInt((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  var RR = R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
  var GG = G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
  var BB = B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);

  return "#" + RR + GG + BB;
};

export const getPager = () => {
  let slides_count = global.showOnePage ? nMax : Math.floor(nMax / 2);
  let pager = null;
  if (nMax > 1) {
    if (serverResponse.config.pager === "index") {
      pager = "index";
    } else if (serverResponse.config.pager === "slider") {
      pager = slides_count < 7 ? "buttons" : "slider";
    } else if (serverResponse.config.pager === "buttons") {
      if (global.showOnePage) {
        pager = slides_count > 8 ? "slider" : "buttons";
      } else {
        pager = slides_count > 15 ? "slider" : "buttons";
      }
    }
  }

  return pager;
};

export const getCustomActions = () => {
  let obj = {
    component: "Default",
    sendList: (exportObj, idParam) => {
      axios
        .post("https://portal.interattivo.net/api/listaspesa/write", exportObj)
        .then(function(response) {
          const encodeString = Buffer.from(
            `client_id=${serverResponse.config.client_id}&signboard_id=${serverResponse.config.signboard_id}&list_id=${idParam}&id_publication=${serverResponse.leaflet.id}`
          ).toString("base64");
          var send_grocery_list_url =
            "https://portal.interattivo.net/clienti/checkout/index?params=" +
            encodeString;
          window.location = send_grocery_list_url;
        })
        .catch(function(error) {
          alert("Spiacenti, si è verificato un errore");
          console.log(error.response);
        });
    },
    bgTotalList: serverResponse.config.primary_color,
    exportList: (exportObj) => {
      axios.post("https://api.volantinointerattivo.net/api/write", exportObj);
    },
    get_read_api_url: (id, id_campaign) =>
      `https://api.volantinointerattivo.net/api/read?id=${id}`,
    get_product_api_url: (res) => res.data,
    backToLeaflet: (func_write, func_history) => null,
    get_list_container_class: "custom-container",
    getShareListLink: (idParam) =>
      serverResponse.config.serverPath + "myGroceryList/" + idParam,
    classColumn: true,
    listData: (el) => el.data,
    isIframe: () => {
      try {
        return window.self !== window.top;
      } catch (error) {
        return;
      }
    },
    cookieConsent: false
  };

  switch (serverResponse.config.client_id) {
    case 137:
      return {
        ...obj,
        component: "Giodicart",
        sendList: (exportObj, idParam, bool, wo = null) => {
          axios
            .post(
              "https://portal.interattivo.net/api/listaspesa/write",
              exportObj
            )
            .then(function(response) {
              let l = `https://www.giodicart.it/vi/${idParam}`;
              const openTo = isMobileSafari ? "_self" : "_blank";

              if (bool) {
                if (isSafari) {
                  wo.location = l;
                } else {
                  window.open(`${l}`, openTo);
                }
              }
            })
            .catch(function(error) {
              alert(`Spiacenti, si è verificato un errore ${error}`);
              console.log(error.response);
            });
          return;
        },
        bgTotalList: "#ff0000",
        exportList: (exportObj) => {
          axios
            .post(
              "https://portal.interattivo.net/api/listaspesa/write",
              exportObj
            )
            .catch(function(error) {
              alert("Spiacenti, si è verificato un errore");
              console.log(error.response);
            });
        },
        get_read_api_url: (id) =>
          `https://portal.interattivo.net/api/listaspesa/read?id_param_url=${id}&id_campagna=${serverResponse.leaflet.id_campaign}`,
        get_product_api_url: (res) => {
          let tmp_product = res.data.data.map((p) => {
            let temp_p = p.prices !== "" ? JSON.parse(p.prices) : null;
            let temp_v = p.variety === "" ? undefined : p.variety;
            return { ...p, prices: temp_p, variety: temp_v };
          });
          return tmp_product;
        },
        backToLeaflet: (func_write, func_history) => {
          func_write();
          func_history.push("/leaflet?cart=1");
        },
        get_list_container_class: "custom-container d-none",
        getShareListLink: (idParam) =>
          serverResponse.leaflet.code === "15"
            ? `https://giodicart.education/c/${idParam}`
            : `https://giodicart.education/p/${serverResponse.config.slug}/c/${idParam}`,
        classColumn: true,
        listData: (el) => el.data.data,
        cookieConsent: true
      };

    default:
      return obj;
  }
};

export const encodeN = (n) => {
  const key = [4, 5, 6, 7, 8, 9, 0, 1, 2, 3];
  return key[n];
};
