import React, { useState } from "react";
// import "../App.css";
import { styles } from "./readMore.style";

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  //   if (text.length < 99999999) {
  //     return null;
  //   }
  return (
    <p style={styles.text}>
      {isReadMore ? text.slice(0, 99999999) : text}
      {text.length >= 99999999 && (
        <p onClick={toggleReadMore} style={styles.read_or_hide}>
          {isReadMore ? "▼ Leggi tutto" : "▲ Leggi meno"}
        </p>
      )}
    </p>
  );
};

export default ReadMore;
