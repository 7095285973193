export const styles = {
  buttonDesktop: {
    height: "38px",
    width: "100%"
  },
  inputDesktop: {
    width: "100%",
    maxWidth: "120px",
    textAlign: "right"
  },
  buttonMobile: {
    position: "absolute",
    top: "55px",
    zIndex: 1,
    marginLeft: "auto",
    marginRight: "auto",
    left: 0,
    right: 0,
    width: "150px",
    opacity: 0.3
  },
  inputMobile: {
    width: "68px",
    textAlign: "right",
    paddingRight: "5px"
  },
  onFocus: {
    opacity: 1
  }
};
