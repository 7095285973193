export const styles = {
  text: {
    display: "inline",
    width: "100%",
    fontSize: "15px"
  },
  read_or_hide: {
    color: "var(--secondary-color)",
    fontWeight: "bold",
    cursor: "pointer"
  }
};
